<template>
  <div class="w-full header shadow-gray-100 shadow-xl">
    <div class="header__logo px-4 py-3">
      <img
        src="https://d3t5wfmbbn1k49.cloudfront.net/img/logo.svg"
        alt="1plusX"
        class="max-h-12"
      />
    </div>
  </div>
</template>