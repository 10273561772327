<template>
  <div
    class="
      modal-overview
      fixed
      z-10
      inset-0
      overflow-y-auto
      w-full
      h-full
      flex
      justify-center
      items-center
      bg-gray-500/90
    "
  >
    <div
      class="
        modal-content
        relative
        inline-block
        align-bottom
        bg-white
        rounded-lg
        text-left
        overflow-hidden
        shadow-xl
        transform
        transition-all
        sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
        px-8
        py-6
      "
    >
      <XIcon
        class="w-6 h-6 absolute right-1 top-2 text-gray-400 cursor-pointer"
        @click="emitCloseEvent"
      />
      <div class="text-sm text-gray-500">
        <slot />
      </div>
      <div class="flex justify-center mt-4">
        <button
          type="button"
          class="
            ml-3
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-primary
            hover:opacity-90
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
          "
          @click="emitCloseEvent"
        >
          {{ $t("MODAL_BUTTON_TEXT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/solid";
export default {
  name: "BaseModal",
  components: {
    XIcon,
  },
  methods: {
    emitCloseEvent() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  ::v-deep ul {
    padding-left: 15px;
    margin: 10px 0;
    li {
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 7px;
        left: -12px;
        position: absolute;
        @apply bg-gray-500;
      }
    }
  }
}
</style>