import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './css/index.css'
import VueHighlightJS from 'vue3-highlightjs'

const app = createApp(App)
const EnglishTranslations = require('../static/en.language.json')
const JapaneseTranslations = require('../static/ja.language.json')
const i18n = createI18n({
  locale: window.location.href.includes('/ja') ? 'ja' : 'en',
  messages: {
    en: EnglishTranslations,
    ja: JapaneseTranslations,
  },
})

app.use(i18n)
app.use(VueHighlightJS)
app.mount('#app')
