<template>
  <div class="form">
    <h1 class="text-center text-3xl font-bold mb-16">{{ $t("TITLE") }}</h1>
    <form @submit.prevent="generate">
      <div class="shadow-lg sm:rounded-md">
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div>
            <h3
              class="
                text-lg
                leading-6
                font-semi-bold
                text-gray-900
                border-b-2 border-slate-100
                pb-2
                mb-5
              "
            >
              {{ $t("EVENT_TYPE_LABEL") }}
            </h3>
            <div>
              <Listbox as="div" v-model="form.selectedEventType">
                <ListboxLabel class="block text-md font-medium text-gray-700">
                  {{ $t("EVENT_TYPE_DESCRIPTION") }}
                  <QuestionMarkCircleIcon
                    @click="
                      showModalWithAdditionalInformation(
                        $t('EVENT_TYPE_ADDITIONAL_DESCRIPTION'),
                        $t('EVENT_TYPE_MODAL_HEADER')
                      )
                    "
                    class="
                      w-5
                      h-5
                      inline-block
                      text-gray-500
                      cursor-pointer
                      align-sub
                    "
                  />
                </ListboxLabel>
                <div class="mt-1 relative">
                  <ListboxButton
                    class="
                      relative
                      w-full
                      border
                      rounded-md
                      shadow-sm
                      px-3
                      py-2
                      text-left
                      focus:outline-none
                      focus:ring-1
                      focus:ring-primary
                      focus:border-primary
                      sm:text-sm
                    "
                    :class="
                      errors.includes('selectedEventType')
                        ? 'border-red-400 text-red-400 placeholder-red-400 font-weight-bold'
                        : 'border-gray-300'
                    "
                    @blur="validate($event, 'selectedEventType')"
                  >
                    <span v-if="form.selectedEventType">{{
                      form.selectedEventType
                    }}</span>
                    <span v-else>{{ $t("EVENT_TYPE_DEFAULT") }}</span>
                    <span
                      class="
                        absolute
                        inset-y-0
                        right-0
                        flex
                        items-center
                        pr-2
                        pointer-events-none
                      "
                    >
                      <SelectorIcon
                        class="w-5 h-5"
                        :class="
                          errors.includes('selectedEventType')
                            ? 'text-red-400'
                            : 'text-gray-400'
                        "
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>
                  <ListboxOptions
                    class="
                      absolute
                      z-10
                      mt-1
                      w-full
                      bg-white
                      text-sm
                      shadow-sm
                      max-h-80
                      rounded-md
                      py-1
                      ring-1 ring-black ring-opacity-5
                      overflow-auto
                      focus:outline-none
                    "
                  >
                    <ListboxOption
                      as="template"
                      v-for="event in eventTypes"
                      :key="event.key"
                      :value="event.key"
                      v-slot="{ active }"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-primary' : 'text-black',
                          'select-none py-2 px-3',
                        ]"
                      >
                        <span>
                          {{ event.value }}
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </div>
              </Listbox>
              <div v-if="isCustomEventType" class="mt-5">
                <div class="relative">
                  <input
                    v-model="form.customEventType"
                    type="text"
                    id="customEventType"
                    name="customEventType"
                    class="
                      shadow-sm
                      focus:ring-primary focus:border-primary
                      block
                      w-full
                      sm:text-sm
                      rounded-md
                    "
                    :class="
                      errors.includes('customEventType')
                        ? 'border-red-400 text-red-400 placeholder-red-400 font-weight-bold'
                        : 'border-gray-300'
                    "
                    :placeholder="$t('CUSTOM_EVENT_TYPE_PLACEHOLDER')"
                    pattern="[a-zA-Z0-9_-]+"
                    @blur="validate($event, 'selectedEventType')"
                  />
                  <span
                    class="
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      pr-2
                      pointer-events-none
                    "
                  >
                    <ExclamationCircleIcon
                      v-if="errors.includes('customEventType')"
                      class="w-6 h-6 text-red-400"
                    />
                  </span>
                </div>
                <p
                  v-if="isCustomEventType"
                  class="text-xs mt-2 ml-1 font-medium text-gray-400"
                >
                  {{ $t("CUSTOM_EVENT_TYPE_DESCRIPTION") }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h3
              class="
                text-lg
                leading-6
                font-semi-bold
                text-gray-900
                border-b-2 border-slate-100
                pb-2
                mb-5
              "
            >
              {{ $t("ATTRIBUTE_KEYS_LABEL") }}
            </h3>
            <div class="grid grid-cols-12">
              <div class="col-span-12">
                <p class="block text-md font-medium text-gray-700">
                  {{ $t("ATTRIBUTE_KEYS_DESCRIPTION") }}
                </p>
              </div>
            </div>
            <div
              v-for="(item, index) in form.keys"
              :key="`key_${index}`"
              class="grid grid-cols-12"
            >
              <div class="col-span-7 md:col-span-6 mt-2 relative">
                <input
                  v-model="form.keys[index].key"
                  type="text"
                  :id="form.keys[index].name"
                  :name="form.keys[index].name"
                  class="
                    shadow-sm
                    focus:ring-primary focus:border-primary
                    block
                    w-full
                    sm:text-sm
                    rounded-md
                  "
                  :class="
                    errors.includes(form.keys[index].name)
                      ? 'border-red-400 text-red-400 placeholder-red-400 font-weight-bold'
                      : 'border-gray-300'
                  "
                  :placeholder="$t('ATTRIBUTE_KEY_PLACEHOLDER')"
                  pattern="[a-zA-Z0-9_-]+"
                  @blur="validate"
                />
                <span
                  class="
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    pr-2
                    pointer-events-none
                  "
                >
                  <ExclamationCircleIcon
                    v-if="errors.includes(form.keys[index].name)"
                    class="w-6 h-6 text-red-400"
                  />
                </span>
              </div>
              <div class="col-span-5 md:col-span-6 mt-2">
                <button
                  type="button"
                  @click="addKey"
                  class="
                    h-full
                    aspect-square
                    ml-2
                    bg-success
                    text-white
                    font-bold
                    text-xl
                    rounded-md
                  "
                >
                  +
                </button>
                <button
                  type="button"
                  v-if="form.keys.length > 1"
                  @click="removeKey(index)"
                  class="
                    h-full
                    aspect-square
                    ml-2
                    bg-danger
                    text-white
                    font-bold
                    text-xl
                    rounded-md
                  "
                >
                  -
                </button>
              </div>
            </div>
            <div>
              <p class="text-xs mt-2 ml-1 mt-1 font-medium text-gray-400">
                {{ $t("ATTRIBUTE_KEYS_REQUIREMENTS_INFORMATION") }}
              </p>
            </div>
          </div>
          <div>
            <h3
              class="
                text-lg
                leading-6
                font-semi-bold
                text-gray-900
                border-b-2 border-slate-100
                pb-2
                mb-5
              "
            >
              {{ $t("CLIENT_IDENTIFIER_LABEL") }}
            </h3>
            <label
              for="clientID"
              class="block text-md font-medium text-gray-700 mb-1"
              >{{ $t("CLIENT_IDENTIFIER_DESCRIPTION") }}</label
            >
            <div class="relative">
              <input
                v-model="form.clientID"
                type="text"
                id="clientID"
                name="clientID"
                class="
                  shadow-sm
                  focus:ring-primary focus:border-primary
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="
                  errors.includes('clientID')
                    ? 'border-red-400 text-red-400 placeholder-red-400 font-weight-bold'
                    : 'border-gray-300'
                "
                :placeholder="$t('CLIENT_IDENTIFIER_PLACEHOLDER')"
                pattern="[a-zA-Z0-9_-]+"
                @blur="validate"
              />
              <span
                class="
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  pr-2
                  pointer-events-none
                "
              >
                <ExclamationCircleIcon
                  v-if="errors.includes('clientID')"
                  class="w-6 h-6 text-red-400"
                />
              </span>
            </div>
            <p class="text-xs mt-2 ml-1 font-medium text-gray-400">
              {{ $t("CLIENT_IDENTIFIER_REQUIREMENTS_INFORMATION") }}
            </p>
          </div>
          <div>
            <h3
              class="
                text-lg
                leading-6
                font-semi-bold
                text-gray-900
                border-b-2 border-slate-100
                pb-2
                mb-5
              "
            >
              {{ $t("TAG_FORMAT_LABEL") }}
              <QuestionMarkCircleIcon
                @click="
                  showModalWithAdditionalInformation(
                    $t('TAG_FORMAT_ADDITIONAL_DESCRIPTION'),
                    $t('TAG_FORMAT_MODAL_HEADER')
                  )
                "
                class="
                  w-5
                  h-5
                  inline-block
                  text-gray-500
                  cursor-pointer
                  align-text-top
                "
              />
            </h3>
            <p class="block text-md font-medium text-gray-700 mb-1">
              {{ $t("TAG_FORMAT_DESCRIPTION") }}
            </p>
            <div class="space-y-2 mt-2 ml-1">
              <div class="flex items-center">
                <input
                  v-model="form.tag_format"
                  id="sdk_tag"
                  name="tag_format"
                  type="radio"
                  value="sdk"
                  class="focus:ring-primary h-4 w-4 text-primary"
                  :class="
                    errors.includes('tag_format')
                      ? 'border-red-400'
                      : 'border-gray-300'
                  "
                  @blur="validate($event, 'tag_format')"
                />
                <label
                  for="sdk_tag"
                  class="ml-3 block text-sm font-medium"
                  :class="
                    errors.includes('tag_format')
                      ? 'text-red-400'
                      : 'text-gray-700'
                  "
                >
                  {{ $t("TAG_FORMAT_SDK_LABEL") }}
                </label>
              </div>
              <div class="flex items-center">
                <input
                  v-model="form.tag_format"
                  id="image_tag"
                  name="tag_format"
                  type="radio"
                  value="img"
                  class="focus:ring-primary h-4 w-4 text-primary"
                  :class="
                    errors.includes('tag_format')
                      ? 'border-red-400'
                      : 'border-gray-300'
                  "
                  @blur="validate($event, 'tag_format')"
                />
                <label
                  for="image_tag"
                  class="ml-3 block text-sm font-medium"
                  :class="
                    errors.includes('tag_format')
                      ? 'text-red-400'
                      : 'text-gray-700'
                  "
                >
                  {{ $t("TAG_FORMAT_IMAGE_TAG") }}
                </label>
              </div>
            </div>
            <div v-if="form.tag_format === 'sdk'">
              <div class="space-y-2 mt-5">
                <p class="block text-md font-medium text-gray-700 mb-1">
                  {{ $t("SDK_IMPLEMENTATION_LABEL") }}
                </p>
                <div class="flex items-center">
                  <input
                    v-model="form.isSDKImplemented"
                    id="sdk_implemented"
                    name="isSDKImplemented"
                    type="radio"
                    class="focus:ring-primary h-4 w-4 text-primary"
                    :class="
                      errors.includes('isSDKImplemented')
                        ? 'border-red-400'
                        : 'border-gray-300'
                    "
                    :value="true"
                    @blur="validate($event, 'isSDKImplemented')"
                  />
                  <label
                    for="sdk_implemented"
                    class="ml-3 block text-sm font-medium"
                    :class="
                      errors.includes('isSDKImplemented')
                        ? 'text-red-400'
                        : 'text-gray-700'
                    "
                  >
                    {{ $t("YES_ANSWEAR") }}
                  </label>
                </div>

                <div class="flex items-center">
                  <input
                    v-model="form.isSDKImplemented"
                    id="sdk_not_implemented"
                    name="isSDKImplemented"
                    type="radio"
                    class="focus:ring-primary h-4 w-4 text-primary"
                    :value="false"
                    :class="
                      errors.includes('isSDKImplemented')
                        ? 'border-red-400'
                        : 'border-gray-300'
                    "
                    @blur="validate($event, 'isSDKImplemented')"
                  />
                  <label
                    for="sdk_not_implemented"
                    class="ml-3 block text-sm font-medium"
                    :class="
                      errors.includes('isSDKImplemented')
                        ? 'text-red-400'
                        : 'text-gray-700'
                    "
                  >
                    {{ $t("NO_ANSWEAR") }}
                  </label>
                </div>
              </div>
              <div
                v-if="!form.isSDKImplemented && form.isSDKImplemented !== null"
                class="mt-4"
              >
                <Listbox as="div" v-model="form.selectedFramework">
                  <ListboxLabel class="block text-md font-medium text-gray-700">
                    {{ $t("PRIVACY_FRAMEWORK_LABEL") }}
                  </ListboxLabel>
                  <div class="mt-1 relative">
                    <ListboxButton
                      class="
                        relative
                        w-full
                        border
                        rounded-md
                        shadow-sm
                        px-3
                        py-2
                        text-left
                        focus:outline-none
                        focus:ring-1
                        focus:ring-primary
                        focus:border-primary
                        sm:text-sm
                      "
                      :class="
                        errors.includes('selectedFramework')
                          ? 'border-red-400 text-red-400 placeholder-red-400 font-weight-bold'
                          : 'border-gray-300'
                      "
                      @blur="validate($event, 'selectedFramework')"
                    >
                      <span v-if="form.selectedFramework">{{
                        form.selectedFramework.value
                      }}</span>
                      <span v-else>{{ $t("PRIVACY_FRAMEWORK_DEFAULT") }}</span>
                      <span
                        class="
                          absolute
                          inset-y-0
                          right-0
                          flex
                          items-center
                          pr-2
                          pointer-events-none
                        "
                      >
                        <SelectorIcon
                          class="w-5 h-5"
                          :class="
                            errors.includes('selectedFramework')
                              ? 'text-red-400'
                              : 'text-gray-400'
                          "
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                    <ListboxOptions
                      class="
                        absolute
                        z-10
                        mt-1
                        w-full
                        bg-white
                        text-sm
                        shadow-sm
                        max-h-80
                        rounded-md
                        py-1
                        ring-1 ring-black ring-opacity-5
                        overflow-auto
                        focus:outline-none
                      "
                    >
                      <ListboxOption
                        as="template"
                        v-for="item in priavacyFrameworks"
                        :key="item.key"
                        :value="item"
                        v-slot="{ active }"
                      >
                        <li
                          :class="[
                            active ? 'text-white bg-primary' : 'text-black',
                            'select-none py-2 px-3',
                          ]"
                        >
                          <span>
                            {{ item.value }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div class="flex justify-end w-full">
            <button
              type="submit"
              class="
                ml-3
                inline-flex
                justify-center
                py-2
                px-4
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-primary
                hover:opacity-90
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary
              "
            >
              {{ $t("SHOW_TAG_BUTTON_TEXT") }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div
      v-if="generatedCode"
      id="output"
      class="mt-12 shadow-lg sm:rounded-md sm:overflow-hidden"
    >
      <div class="px-4 py-5 bg-white sm:p-6">
        <h3
          class="
            text-lg
            font-semi-bold
            text-gray-900
            border-b-2 border-slate-100
            pb-2
            mb-5
          "
        >
          {{ $t("GENERATED_TAG_LABEL") }}
        </h3>
        <pre v-highlightjs class="flex mb-2">
          <code class="javascript whitespace-pre overflow-x-scroll bg-gray-100 p-4 sm:rounded-md text-sm w-full" v-text="generatedCode"></code>
        </pre>
        <p
          class="text-xs mt-2 ml-1 font-medium text-gray-400 mb-2"
          v-html="$t('GENERATED_TEXT_DESCRIPTION')"
        ></p>
        <div
          :class="textCopied ? 'justify-between' : 'justify-end'"
          class="flex w-full"
        >
          <div
            v-if="textCopied"
            class="font-bold text-sm self-center text-green-600"
          >
            {{ $t("COPY_TO_CLIPBOARD_SUCCESSFUL") }}
          </div>
          <button
            type="submit"
            class="
              ml-3
              inline-flex
              justify-center
              py-2
              px-4
              border border-transparent
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-white
              bg-primary
              hover:opacity-90
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-primary
            "
            @click="copyToClipboard"
          >
            {{ $t("COPY_TO_CLIPBOARD_BUTTON_TEXT") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <BaseModal @close-modal="modal.visible = !modal.visible" v-if="modal.visible">
    <h3 v-if="modal.header" class="font-semibold text-lg mb-2">
      {{ modal.header }}
    </h3>
    <div class="mb-2" v-html="modal.content"></div>
  </BaseModal>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { SelectorIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { ExclamationCircleIcon } from "@heroicons/vue/outline";
import { nextTick } from "vue";
import BaseModal from "./BaseModal";

export default {
  name: "TheForm",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    SelectorIcon,
    ExclamationCircleIcon,
    QuestionMarkCircleIcon,
    BaseModal,
  },
  data() {
    return {
      eventTypes: [
        {
          value: this.$i18n.t("EVENT_TYPE_ARTICLE_METADATA"),
          key: "ArticleMetadata",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_REGISTRATION"),
          key: "Registration",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_IDSYNC"),
          key: "IDSync",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_CAMPAIGN_TRACKING"),
          key: "CampaignTracking",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_VIDEO_DATA"),
          key: "VideoData",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_GROUND_TRUTH"),
          key: "GroundTruth",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_PAYWALL"),
          key: "Paywall",
        },
        {
          value: this.$i18n.t("EVENT_TYPE_CUSTOM"),
          key: "Custom",
        },
      ],
      priavacyFrameworks: [
        {
          key: "tcf2",
          value: this.$i18n.t("PRIVACY_FRAMEWORK_TCF"),
        },
        {
          key: "usp",
          value: this.$i18n.t("PRIVACY_FRAMEWORK_USP"),
        },
        {
          key: "implied",
          value: this.$i18n.t("PRIVACY_FRAMEWORK_IMPLIED"),
        },
      ],
      isCustomEventType: false,
      textCopied: false,
      form: {
        selectedEventType: null,
        customEventType: null,
        keys: [{ key: "", name: "key_0" }],
        clientID: "",
        tag_format: null,
        isSDKImplemented: null,
        selectedFramework: null,
      },
      generatedCode: null,
      errors: [],
      modal: {
        content: null,
        header: null,
        visible: false,
      },
    };
  },
  watch: {
    form: {
      handler(value) {
        this.isCustomEventType = value.selectedEventType === "Custom";
        if (this.generatedCode) {
          this.generatedCode = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    const clientID = new URLSearchParams(document.location.search).get(
      "client"
    );
    if (clientID && clientID !== null) {
      this.form.clientID = clientID;
    }
  },
  methods: {
    addKey() {
      this.form.keys.push({
        key: "",
        name: `key_${this.form.keys.length}`,
      });
    },
    removeKey(index) {
      this.form.keys.splice(index, 1);
    },
    generateTagCode(option) {
      const eventType = this.isCustomEventType
        ? this.form.customEventType
        : this.form.selectedEventType;
      switch (option) {
        case "img": {
          const keys = this.form.keys
            .map((item) => {
              return item.key;
            })
            .join("=%CustomValue%&");
          this.generatedCode = `<img src="https://${this.form.clientID}.tagger.opecloud.com/${this.form.clientID}/v2/custom-no-tagger?type=${eventType}&${keys}=%CustomValue%">`;
          break;
        }
        case "sdk": {
          const keys = this.form.keys
            .map((item) => {
              return item.key;
            })
            .join(`: '%CustomValue%',\n  `);
          this.generatedCode = `ope("${this.form.clientID}", "send", "${eventType}", { \n  ${keys}: '%CustomValue%'\n});`;
          break;
        }
        case "sdkWithoutFramework": {
          const keys = this.form.keys
            .map((item) => {
              return item.key;
            })
            .join(`: '%CustomValue%',\n  `);
          // eslint-disable-next-line
          this.generatedCode = `<!-- 1plusX -->\n<script>\n(function(o,n,e,p,l,u,s){o[l]=o[l]||function(){(o[l].q=o[l].q||[]).push(arguments);};\nu=n.createElement(e);u.async=1;u.src=p;s=n.getElementsByTagName(e)[0];s.parentNode.insertBefore(u,s);\n}(window,document,"script","https://cdn.opecloud.com/ope-${this.form.clientID}.js","ope"));\n\nope("${this.form.clientID}", "init", "${this.form.selectedFramework.key}");\n<\/script>\n<!-- end 1plusX -->\n\nope("${this.form.clientID}", "send", "${eventType}", { \n  ${keys}: '%CustomValue%'\n});`;
          break;
        }
      }
    },
    generate() {
      this.errors = [];
      if (!this.form.selectedEventType) {
        this.errors.push("selectedEventType");
      }
      if (!this.form.tag_format) {
        this.errors.push("tag_format");
      }
      if (
        this.form.tag_format === "sdk" &&
        this.form.isSDKImplemented === null
      ) {
        this.errors.push("isSDKImplemented");
      }
      if (
        this.form.tag_format === "sdk" &&
        !this.form.isSDKImplemented &&
        this.form.isSDKImplemented !== null &&
        !this.form.selectedFramework
      ) {
        this.errors.push("selectedFramework");
      }
      if (!this.form.clientID) {
        this.errors.push("clientID");
      }
      if (this.isCustomEventType && !this.form.customEventType) {
        this.errors.push("customEventType");
        this.errors.push("selectedEventType");
      }
      this.form.keys.forEach((item) => {
        if (!item.key && item.key === "") {
          this.errors.push(item.name);
        }
      });
      if (this.errors.length > 0) {
        return;
      }
      let generateOption = null;
      if (this.form.tag_format === "sdk" && !this.form.isSDKImplemented) {
        generateOption = "sdkWithoutFramework";
      } else if (this.form.tag_format === "sdk" && this.form.isSDKImplemented) {
        generateOption = "sdk";
      } else {
        generateOption = "img";
      }
      this.generateTagCode(generateOption);
      nextTick(() => {
        const output = document.getElementById("output");
        if (output) {
          output.scrollIntoView({
            block: "start",
            behavior: "smooth",
            inline: "nearest",
          });
          this.addHighlightToCustomString();
        }
      });
    },
    copyToClipboard() {
      const generatedCode = this.generatedCode.replaceAll(
        "'%CustomValue%'",
        "%CustomValue%"
      );
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(generatedCode);
      } else {
        //Fallback
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = generatedCode;
        tempTextArea.setAttribute("readonly", "");
        tempTextArea.style.position = "absolute";
        tempTextArea.style.left = "-9999px";
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
      }

      this.textCopied = true;
      setTimeout(() => {
        this.textCopied = false;
      }, 3000);
    },
    validate(e, value) {
      if (e.target && !e.target.validity.valid) {
        this.errors.push(e.target.id);
      } else {
        this.errors = this.errors.filter((item) => {
          return item !== e.target.id && item !== value;
        });
      }
    },
    addHighlightToCustomString() {
      if (this.form.tag_format === "img") {
        document.querySelectorAll(".hljs-string").forEach((item) => {
          const splitedStrings = item.textContent.split("%CustomValue%");
          const joinedStrings = splitedStrings.join(
            "<span class='hljs-string text-red-700'>%CustomValue%</span>"
          );
          item.innerHTML = joinedStrings;
        });
      } else {
        document.querySelectorAll(".hljs-string").forEach((item) => {
          if (item.textContent === "'%CustomValue%'") {
            item.innerHTML = "<span class='text-red-700'>%CustomValue%</span>";
          }
        });
      }
    },
    showModalWithAdditionalInformation(content, header) {
      this.modal = {
        content: content,
        visible: true,
        header: header,
      };
    },
  },
};
</script>
