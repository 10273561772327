<template>
  <div>
    <TheHeader />
    <div class="sm:container px-4 py-16">
      <div class="grid grid-cols-12 gap-4">
        <div class="md:col-start-4 col-span-12 md:col-span-6">
          <TheForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheForm from "./components/TheForm.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheForm,
  },
};
</script>
